import React from 'react';
import classNames from 'classnames';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import CardEvent from '../CardEvent';
import Card from '../Card';
import CardGuide from '../CardGuide';
import { ucFirst } from '../../utils/caseconverters';

import styles from './CardSlider.module.scss';
import isEmpty from '../../utils/isEmpty';
import CardLocal from '../CardLocal';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const CardSlider = ({
    items,
    className,
    content,
    modifiers,
    color,
    onCardClick,
}) => {
    const sliderSettings = {
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
        breakpoints: {
            350: {
                slidesPerView: 'auto',
            },
        },
        speed: 600,
        navigation: {
            prevEl: '.' + styles['CardSlider__Button--Prev'],
            nextEl: '.' + styles['CardSlider__Button--Next'],
        },
        watchSlidesVisibility: true,
    };

    return (
        <div
            className={classNames(
                content
                    ? styles['CardSlider__Container--' + ucFirst(content)]
                    : '',
                styles['CardSlider__Container'],
                styles[`CardSlider--` + color],
                className,
                modifiers.map((x) => ' ' + x)
            )}>
            <Swiper
                {...sliderSettings}
                className={classNames(styles['CardSlider__Slider'], 'Slider', {
                    [styles['CardSlider__Slider--HideDisabled']]:
                        items && items.length < 5,
                })}>
                <div
                    className={
                        styles['CardSlider__Button'] +
                        ' ' +
                        styles['CardSlider__Button--Prev']
                    }
                />
                <div
                    className={
                        styles['CardSlider__Button'] +
                        ' ' +
                        styles['CardSlider__Button--Next']
                    }
                />

                {items &&
                    items.map((item, i) => {
                        if (isEmpty(item)) {
                            return null;
                        }
                        return (
                            <SwiperSlide
                                className={classNames(
                                    styles['CardSlider__Slide'],
                                    'Slide'
                                )}
                                key={i}>
                                {({ isVisible }) =>
                                    item.startDate ? (
                                        <CardEvent
                                            {...item}
                                            tabIndex={isVisible ? '0' : '-1'}
                                            id={Number(item)}
                                            startDate={new Date(item.startDate)}
                                            endDate={new Date(item.endDate)}
                                            inSlider={true}
                                            modifiers={[
                                                color === 'White' && 'White',
                                            ]}
                                            onCardClick={onCardClick}
                                        />
                                    ) : content === 'guides' ? (
                                        <CardGuide
                                            className={
                                                styles[
                                                    'CardSlider__Card--Guide'
                                                ]
                                            }
                                            tabIndex={isVisible ? '0' : '-1'}
                                            modifiers={[
                                                styles[
                                                    'CardSlider__Card--Guide'
                                                ],
                                            ]}
                                            {...item}
                                            onCardClick={onCardClick}
                                        />
                                    ) : ( content === 'Locals' && item?.guide?.text )? (
                                        <CardLocal
                                            tabIndex={isVisible ? '0' : '-1'}
                                            localTitle={item.title}
                                            localImage={item.image}
                                            guideTitle={
                                                item?.guide?.text || item.title
                                            }
                                            guideImage={
                                                item?.guide?.image || item.image
                                            }
                                            href={
                                                item?.guide?.href || item.href
                                            }
                                            onCardClick={onCardClick}
                                        />
                                    ) : (
                                        <Card
                                            tabIndex={isVisible ? '0' : '-1'}
                                            modifiers={[
                                                styles['CardSlider__Card'],
                                            ]}
                                            {...item}
                                            color={color}
                                            type={
                                                content ? ucFirst(content) : ''
                                            }
                                            onCardClick={onCardClick}
                                        />
                                    )
                                }
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};

CardSlider.propTypes = {
    className: PropTypes.string,
    visibleSlides: PropTypes.any,
    items: PropTypes.array.isRequired,
    content: PropTypes.oneOf(['locals', 'events', 'guides', 'Design1']),
    modifiers: PropTypes.array,
    color: PropTypes.string,
    onCardClick: PropTypes.func,
};

CardSlider.defaultProps = {
    className: null,
    visibleSlides: null,
    items: [],
    content: null,
    modifiers: [],
    color: '',
    onCardClick: () => null,
};

export default CardSlider;
