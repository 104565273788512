import React from 'react';
import classNames from 'classnames';
import Byline from '../Byline';
import Icon from '../Icon';
import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './CardGuide.module.scss';

const CardGuide = ({
    href,
    title,
    image,
    category,
    byline,
    onClick,
    showRemoveButton,
    fullWidth,
    onLocalsPage,
    inMyList,
    modifiers,
    onCardClick,
    tabIndex,
}) => {
    const hasByline = byline?.author?.name;
    const hasButton = showRemoveButton;
    const isFullWidth = fullWidth;
    const twoColumns = onLocalsPage;

    const handleOnClick = (e) => {
        e.preventDefault();
        onClick();
    };
    return (
        <div
            className={classNames(
                {
                    [styles['CardGuide--WithByline']]: hasByline,
                    [styles['CardGuide--ThreeColumns']]: hasButton,
                    [styles['CardGuide--MyList']]: inMyList,
                    [styles['CardGuide--LocalsPage']]: onLocalsPage,
                    [styles['CardGuide--TwoColumns']]:
                        twoColumns && !isFullWidth,
                },
                modifiers.map((x) => ' ' + x)
            )}
        >
            <div
                className={classNames(styles['CardGuide__Container'], {
                    [styles['CardGuide__Container--WithByline']]: hasByline,
                    [styles['CardGuide__Container--FullWidth']]: isFullWidth,
                })}
            >
                <a
                    className={styles['CardGuide__Link']}
                    onClick={() => onCardClick && onCardClick(title, category)}
                    href={href}
                    tabIndex={tabIndex}
                >
                    <span className="sr-only">{title}</span>
                </a>
                {hasButton && (
                    <div
                        className={styles['CardGuide__RemoveButton']}
                        onClick={(e) => handleOnClick(e)}
                    >
                        <Icon type="close" size="xs" color="black800" />
                    </div>
                )}
                <div className={styles['CardGuide__Picture']}>
                    {image && image.renditions && (
                        <img
                            width={405}
                            height={280}
                            sizes="343px"
                            srcSet={`${image.renditions.small.src} 600w,
                             ${image.renditions.medium.src} 1200w`}
                            src={image.renditions.small.src}
                            alt={image.alt}
                            style={{
                                objectPosition:
                                    image.focal.x + ' ' + image.focal.y,
                            }}
                        />
                    )}
                    {image && !image.renditions && (
                        <div className={styles['NoImage']} />
                    )}
                </div>

                <div
                    className={classNames(styles['CardGuide__Shadow'], {
                        [styles['CardGuide__Shadow--NoImage']]:
                            image && !image.renditions,
                    })}
                ></div>

                <div
                    className={classNames(styles['CardGuide__Information'], {
                        [styles['CardGuide--WithByline']]: hasByline,
                    })}
                >
                    {((!showRemoveButton && !twoColumns) || isFullWidth) && (
                        <>
                            {title && (
                                <span
                                    className={classNames(
                                        styles['CardGuide__InformationHeading'],
                                        {
                                            [styles[
                                                'CardGuide__InformationHeading--AddMargin'
                                            ]]: !category,
                                        }
                                    )}
                                >
                                    {title && title}
                                </span>
                            )}

                            {category && (
                                <p
                                    className={
                                        styles['CardGuide__InformationCategory']
                                    }
                                >
                                    <span className="sr-only">
                                        {i18n.t('generic.categories')} :
                                    </span>
                                    {category}
                                </p>
                            )}
                        </>
                    )}

                    {hasByline && (
                        <div>
                            <Byline size="small" {...byline} />
                        </div>
                    )}
                </div>
            </div>
            {(Boolean(showRemoveButton) || Boolean(twoColumns)) && (
                <div className={styles['CardGuide__Information--Under']}>
                    {title && (
                        <span
                            className={styles['CardGuide__InformationHeading']}
                        >
                            {title && title}
                        </span>
                    )}
                    {category && (
                        <p
                            className={classNames(
                                styles['CardGuide__InformationCategory'],
                                styles['CardGuide__InformationCategory--Under']
                            )}
                        >
                            <span className="sr-only">
                                {i18n.t('generic.categories')} :
                            </span>
                            {category}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

CardGuide.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    byline: PropTypes.object,
    href: PropTypes.string,
    category: PropTypes.string,
    onClick: PropTypes.func,
    inMyList: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onLocalsPage: PropTypes.bool,
    onCardClick: PropTypes.func,
    modifiers: PropTypes.array,
};

CardGuide.defaultProps = {
    title: null,
    image: null,
    byline: null,
    category: null,
    href: '/',
    onClick: null,
    inMyList: false,
    fullWidth: false,
    onLocalsPage: false,
    modifiers: [],
};

export default CardGuide;
